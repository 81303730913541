import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import ReactPlayer from "react-player"
import { CloseIcon } from "./../styles/GlobalStyles"
import qs from "query-string"

const _window =
  typeof window !== "undefined"
    ? window
    : {
        location: {
          search: "",
        },
      }

const VideoModal = () => {
  const [videoSrc, setVideoSrc] = useState("")
  const _id = "yQtxA8WtdTI"

  useEffect(() => {
    if (_window.location.search) {
      const _temp = qs.parse(_window.location.search)
      if (_temp.videosrc) {
        setVideoSrc(_temp.videosrc)
      }
    }
  }, [_window.location.search])

  const _url = `https://youtu.be/${videoSrc}`

  console.log(_url)

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => {
        return (
          <div className="videoPlayContainer">
            {modal ? (
              <Link to={closeTo}>
                <CloseIcon />
              </Link>
            ) : null}
            <div className="video">
              <ReactPlayer
                url={_url}
                width="100%"
                height="530px"
                controls={true}
              />
            </div>
          </div>
        )
      }}
    </ModalRoutingContext.Consumer>
  )
}

export default VideoModal
